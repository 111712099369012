import { Link } from 'gatsby';
import React from 'react';
import './style.scss';

const Footer = () => {
  return (
    <footer id="contact-us">
      <div className="container">
        <div className="row">
          <div className="contact-us">
            <ul className="policy">
              <li>
                <Link to="/policy">이용약관</Link>
              </li>
              <li>
                <Link to="/private">개인정보취급방침</Link>
              </li>
            </ul>

            <p className="company-name">디지엠유닛원 주식회사</p>

            <ul>
              <li>
                <strong>대표이사</strong> 권혁태
              </li>
              <li>
                <strong>개인정보보호책임자</strong> 정경영
              </li>
              <li>
                <strong>사업자등록번호</strong> 252-86-01619
              </li>
            </ul>

            <ul>
              <li>
                <strong>주소</strong>{' '}
                <address>서울특별시 금천구 가산디지털1로 83, 6층 601호(가산동, 파트너스타워)</address>
              </li>
              <li>
                <strong>이메일</strong> unit1@dgmit.com
              </li>
            </ul>

            <p className="copyright">COPYRIGHT&copy; DGMIT UNIT1. ALL RIGHTS RESERVED</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
